@import "../../colours";
@import "../../dimensions";
@import "./placeholder-animation";

.placeholder-text-elem {
  width: 100%;
  display: block;
  background-color: $grey-light;
  border-radius: $pill-border-radius;
  @include placeholder-animation;
  overflow: hidden;
  position: relative;

  &::before {
    display: block;
    width: 30%;
    height: 200%;
    position: absolute;
    left: 0;
    top: -80%;
    transform: rotate(10deg);
    animation-name: before-swish;
    animation-duration: 1600ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.4) 25%, rgba(255,255,255,0.4) 75%, rgba(255,255,255,0) 100%);
    content:""
  }

  @keyframes before-swish {
    0% { transform: translateX(-100%) }
    100% { transform: translateX(1600%) }
  }
}