.screen-title {
  display: block;
  font-size: xx-large;
  font-weight: 800;

  .let {
    display: inline-block;
    animation-name: letter-appear;
    animation-duration: 220ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }

  .space {
    width: 1rem;
  }

  // CADE 07/11/22 - RIP Animation. ~ Construct Apps
  // CADE 24/01/23 - It lives! ~ Bullitt
  @for $i from 1 to 100 {
    .let:nth-child(#{$i}) { animation-delay: $i * 10ms; }
  }

  @keyframes letter-appear {
    0% { transform: translateY(40%); opacity : 0; }
    80% { transform: translateY(-5%); opacity: 1; }
    100% { transform: translateY(0); }
  }
}