@import "../../colours";

/* TEXT COLOURS */
$table-text-colour: #000;
$table-text-colour-inverse: #FFF;
$table-header-text-color: #FFF;
/* BORDER COLOUR */
$table-border-color: #000;
/* BACKGROUND COLOURS */
$table-header-background: #000;
$table-body-background: #FFF;
$table-body-background-hover: #E4E4E4;
$table-body-background-alt: #EFEFEF;
$table-body-background-alt-hover: #D3D3D3;
/* PAGINATION COLOURS */
$table-pagination-border: #000;
$table-pagination-background: #FFF;
$table-pagination-background-hover: #EFEFEF;

.ep-table {
  width: 100%;

  thead {
    background: $table-header-background;
    color: $table-header-text-color;
    font-weight: bold;

    th {
      padding: 5px;
      border: 1px solid $table-border-color;
      text-transform: uppercase;
      font-size: small;
    }
  }

  tbody {
    background-color: $table-body-background;

    tr {
      border: 1px solid $table-border-color;
      transition: background-color 100ms, color 100ms;

      &:hover {
        background: $table-body-background-hover;
      }

      td {
        border: 1px solid $table-border-color;
        padding: 5px;
      }
    }

    .empty-message {
      text-align: center;
      font-weight: bold;
      text-transform: uppercase;
      font-size: small;
    }
  }

  &.clickable {
    tbody {
      tr {
        cursor: pointer;
      }
    }
  }

  &.alternating {
    tbody {
      tr {
        &:nth-child(even) {
          background: $table-body-background-alt;

          &:hover {
            background: $table-body-background-alt-hover;
          }
        }
      }
    }
  }

  .sortable {
    cursor: pointer;
    background-position: right center;
    background-repeat: no-repeat;
    padding-right: 20px;
  }

}

.ep-table-paginate-container {
  display: flex;
  flex-direction: row;

  .push {
    flex: 1 1;
  }
}

.ep-table-paginate {
  display: flex;
  flex-direction: row;
  border: 1px solid $grey-light;
  border-radius: 5px;
  overflow: hidden;
  color: $text-colour;

  .page-item, .page-next, .page-prev, .page-gap {
    padding: 5px 10px;
    border-right: 1px solid $grey-light;
    cursor: pointer;
  }

  .page-item {
    background: $background-primary;

    &:hover {
      background: $grey-xlight;
    }

    &.active {
      font-weight: bold;
    }
  }

  .page-gap {
    cursor: default;
  }

  .page-next, .page-prev {
    background: $background-primary;
    color: $text-colour;

    &:hover {
      background: $grey-xlight;
    }
  }

  .page-next {
    border: 0;
  }

}