.loading-spinner {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;

  &.inline {
    width: auto;
    display: inline-block;
  }

  &.small {
    .spinner-spin {
      width: 40px !important;
      height: 40px !important;
    }
  }

  .spinner-spin {
    width: 120px;
    height: 120px;
    display: block;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    animation-name: loading-spinner-animate;
    animation-duration: 1200ms;
    animation-iteration-count: infinite;
    margin: auto;
  }

  @keyframes loading-spinner-animate {
    0% { opacity: 0.4 }
    50% { opacity: 0.3 }
    100% { opacity : 0.4 }
  }

}