@import "../../colours";

$toast-animation-duration : 1200ms;
$icon-scale : 1.4;

.toast-container-component {
  width: 350px;
  max-width: 100%;
  max-height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  z-index:9999;
  padding: 0 0 10px 0;
  overflow-y: auto;
  overflow-x: hidden;

  .toast-item {
    width: 100%;
    min-height: 90px;
    position: relative;
    display: grid;
    grid-template-columns: 40px 1fr;
    grid-template-rows: 1fr;
    margin-top: 10px;
    align-items: center;
    background: blue;
    animation-name: toast-did-appear;
    animation-duration: ($toast-animation-duration / 4);
    animation-fill-mode: forwards;
    border-radius: 5px 0 0 5px;
    box-shadow: 0 0 10px $shadow-light;

    &.dismissing {
      min-height: 0;
      animation-name: toast-did-dismiss;
      animation-duration: 800ms;
      transform: translateX(100%);
      overflow: hidden;
    }

    .toast-close {
      width: 20px;
      height: 20px;
      cursor: pointer;
      background-size: 10px 10px;
      background: no-repeat center;
      position: absolute;
      right: 0;
      top: 0;
      display: block;
      z-index: 9999;
      animation-duration: 200ms;
      animation-delay: $toast-animation-duration;
      animation-name: toast-close-did-appear;
      animation-fill-mode: forwards;
      opacity: 0;
    }

    .toast-icon {
      width: 30px;
      height: 60px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      display: block;
      margin:5px;
      animation-name: toast-icon-did-appear;
      animation-delay: ($toast-animation-duration / 5);
      animation-duration: $toast-animation-duration;
      animation-timing-function: ease-in;
      animation-fill-mode: forwards;
      transform: scale($icon-scale);
      opacity: 0;
    }

    .toast-content {
      flex: 1 1;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      .toast-underlay {
        width: 100%;
        font-size: xx-large;
        font-weight: 800;
        color: white;
        position: absolute;
        display: flex;
        flex-direction: column;
        left: 0;
        top: 0;
        bottom: 0;
        animation-name: toast-underlay-did-appear;
        animation-delay: ($toast-animation-duration / 6);
        animation-duration: $toast-animation-duration;
        animation-timing-function: ease-in;
        animation-fill-mode: forwards;
        transform: translateX(10%);
        opacity: 0;
        margin-left: 10px;
        text-transform: uppercase;

        .spacer {
          flex: 1 1;
        }
      }

      .toast-overlay {
        width: 100%;
        height: 100%;
        background: white;
        animation-name: toast-overlay-did-appear;
        animation-delay: $toast-animation-duration;
        animation-duration: ($toast-animation-duration / 4);
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;
        transform: translateX(100%);

        .title {
          font-size: x-large;
          font-weight: 800;
          color: blue;
          text-transform: uppercase;
          display:block;
          padding: 5px 5px 0 5px;
          margin: 0 !important;
        }

        .body {
          font-size: large;
          display: block;
          padding: 0 5px 5px 5px;
        }

        .actions {
          display: block;
          padding: 0 5px 0 5px;

          .action-item {
            font-size: small;
            text-transform: uppercase;
            padding: 5px 10px;
            border-radius: 9999px;
            background: $grey-primary;
            color: $text-colour-inverse;
            font-weight: bold;
            cursor: pointer;
            margin-right: 5px;
            display: inline-block;
            margin-bottom: 5px;
          }
        }
      }
    }

    &.success {
      background: $green-primary-alt;

      .toast-content {
        .toast-overlay {
          .title {
            color: $green-primary-alt;
          }
        }
      }
    }

    &.danger {
      background: $red-primary-alt;

      .toast-content {
        .toast-overlay {
          .title {
            color: $red-primary-alt
          }
        }
      }
    }

    &.warning {
      background: $orange-primary-alt;

      .toast-content {
        .toast-overlay {
          .title {
            color: $orange-primary-alt
          }
        }
      }
    }

    &.info {
      background: $blue-primary-alt;

      .toast-content {
        .toast-overlay {
          .title {
            color: $blue-primary-alt
          }
        }
      }
    }

  }
}

@keyframes toast-did-appear {
  0% {transform: translateX(100%) }
  100% { transform: translateX(0%) }
}

@keyframes toast-did-dismiss {
  0% { transform: translateX(0%); min-height: 90px; }
  80% { transform: translateX(100%); min-height: 90px; }
  81% { transform: translateX(100%); height: 90px; min-height: 0; }
  100% { height: 0; min-height: 0; overflow: hidden; margin-top: 0; }
}

@keyframes toast-close-did-appear {
  0% { opacity : 0 }
  100% { opacity: 1 }
}

@keyframes toast-icon-did-appear {
  0% {transform: scale($icon-scale) translateX(20%); opacity: 0}
  1% {transform: scale($icon-scale) translateX(20%); opacity: 0}
  20% {transform: scale($icon-scale) translateX(10%); opacity: 1}
  80% {transform: scale($icon-scale) translateX(10%); opacity: 1}
  100% { transform: scale(1); opacity : 1 }
}

@keyframes toast-underlay-did-appear {
  0% { transform: translateX(50%); opacity: 0}
  20% { transform: translateX(0%); opacity : 1}
  80% { transform: translateX(0%); opacity : 1}
  100% { opacity: 0 }
}

@keyframes toast-overlay-did-appear {
  0% { transform: translateX(100%); }
  80% { transform: translateX(5%) }
  100% { transform: translateX(0%) }
}