/* Shinjuku */
$blue-primary : rgba(0,114,255, 0.8);
$blue-primary-alt : rgba(0,144,255,1);

/* Akihabara */
$purple-primary : rgba(108,0,255,0.8);
$purple-primary-alt : rgba(108,0,255,1);

/* Ueno */
$pink-primary : rgba(255,0,189,0.8);
$pink-primary-alt : rgba(255,0,189,1);

/* Ikebukero */
$red-primary : rgba(255,62,81,0.8);
$red-primary-alt : rgba(255,62,81,1);

/* Minato */
$orange-primary : rgba(255,75,0,0.8);
$orange-primary-alt : rgba(255,75,0,1);

/* Ginza */
$green-primary : rgba(0,255,84,0.8);
$green-primary-alt : rgba(0,255,84,1);

$white : #FFF;
$black : #000;

$grey-xlight : #EFEFEF;
$grey-light: #DEDEDE;
$grey-primary : #BBB;
$grey-secondary : #999;
$grey-dark: #2B2B2B;

$shadow-light : rgba(0,0,0,0.1);
$shadow-faint : rgba(0,0,0,0.22);
$shadow-medium: rgba(0,0,0,0.3);
$shadow-heavy : rgba(0,0,0,0.5);

$background-primary : $white;
$background-primary-inverse : #0A0A0A;

$text-colour : $black;
$text-colour-inverse : $white;

$border-xlight : 1px solid rgba(0,0,0,0);

$dark-background : #1D1D1D;

$scrim-light : rgba(255, 255, 255, 0.8);
$scrim-dark : rgba(0, 0, 0, 0.8);

/*
BRAND COLOURS
 */

$brand-primary : #FD734E;
$brand-primary-alt : darken($brand-primary, 10%);

$brand-secondary: #ffd54b;
$brand-secondary-alt: darken($brand-secondary, 10%);
