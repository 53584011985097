$spinnerBoxSizeNormal : 30px;
$spinnerContainerWidth : ($spinnerBoxSizeNormal * 6);

$animation-duration : 1200ms;

$box-colour : #000;
$box-colour-inverse : #FFF;

.loading-spinner {
  width : $spinnerContainerWidth;
  height : $spinnerBoxSizeNormal;
  display: inline-block;
  position: relative;

  &.inverted {
    .loading-box {
      background: $box-colour-inverse;
    }
  }

  .loading-box {
    width: $spinnerBoxSizeNormal;
    height : $spinnerBoxSizeNormal;
    background: $box-colour;
    position: absolute;

    animation-duration: $animation-duration;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  .hero-box {
    animation-name: hero-box-movement;
  }

  .static-box-one {
    left : ($spinnerContainerWidth * 0.25) - ($spinnerBoxSizeNormal / 2);
    animation-name: static-box-one-fade;
  }

  .static-box-two {
    left : ($spinnerContainerWidth * 0.5) - ($spinnerBoxSizeNormal / 2);
    animation-name: static-box-two-fade;
  }

  .static-box-three {
    left : ($spinnerContainerWidth * 0.75) - ($spinnerBoxSizeNormal / 2);
    animation-name: static-box-three-fade;
  }

  @keyframes hero-box-movement {
    0% { opacity : 0; left : 0;}
    10% { opacity : 1 }
    90% { opacity : 1 }
    100% {opacity : 0; left: 100% }
  }

  @keyframes static-box-one-fade {
    0% { opacity : 0 }
    25% { opacity : 0 }
    26% { opacity : 1 }
    80% { opacity : 1 }
    100% { opacity : 0 }
  }

  @keyframes static-box-two-fade {
    0% { opacity : 0 }
    50% { opacity : 0 }
    51% { opacity : 1 }
    80% { opacity : 1 }
    100% { opacity : 0 }
  }

  @keyframes static-box-three-fade {
    0% { opacity : 0 }
    75% { opacity : 0 }
    76% { opacity : 1 }
    80% { opacity : 1 }
    100% { opacity : 0 }
  }

}