@mixin placeholder-animation {
  animation-name: placeholder-anim;
  animation-iteration-count: infinite;
  animation-duration: 1200ms;
  animation-timing-function: ease-in-out;
}

@keyframes placeholder-anim {
  0% { opacity: 1; }
  50% { opacity : 0.8 }
  100% { opacity: 1 }
}