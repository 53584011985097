@import "../../colours";
@import "../../dimensions";

.tokyo-header-bar {
  padding: 20px 0 20px 0;
  //display: flex;
  //align-items: center;

  .logo-area {
    display: flex;
    max-width: 340px;
    min-height: 80px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    font-size: 3rem;
    font-weight: 800;
    text-align: center;
    align-items: center;
    padding: 0 30px;
    position: relative;

    &.logo-rectangle {
      width: 240px;
      height: 80px;
      padding: 0;
    }

    &.logo-square {
      width: 80px;
      height: 80px;
      padding: 0;
    }

    .logo-text {
      width: auto;
      display: inline-block;
      text-align: center;
    }

    .tag-text {
      position: absolute;
      top: 0;
      right: 0;
      background: $brand-primary-alt;
      color: $text-colour-inverse;
      font-weight: bold;
      padding: 2px 5px;
      font-size: x-small;
      border-radius: 999px;
      cursor: default;
    }
  }

  .navigation-area {
    //flex: 1 1;

    .nav-pill {
      font-size: large;
      font-weight: bold;
      border-radius: $pill-border-radius;
      padding: $pill-padding-vertical $pill-padding-horizontal;
      cursor: pointer;
      margin-left: 10px;
      display: inline-block;
      border: $border-xlight;
      color: $blue-primary-alt;
      text-decoration: none;

      &:hover {
        border: 1px solid $grey-primary;
      }

      &.active {
        background: $blue-primary;
        color: $text-colour-inverse;
      }

    }
  }

  .account-area {
    display: inline-block;
    cursor: pointer;

    .account-circle {
      width: 60px;
      height: 60px;
      display:block;
      border: 2px solid $blue-primary;
      border-radius: 50%;
    }
  }
}