.notification-preview-modal {

  .summary-count {

    .number {
      font-size: x-large;
      font-weight: 800;
    }

    .label {
      font-size: medium;
      font-weight: 800;
    }

  }

  .notification-preview {
    display: block;
    border-radius: 5px;
    border: 1px solid #AAA;
    padding: 10px;

    .top-line {
      width: 100%;
      display: flex;
      text-align: left;

      .notification-icon {
        width: 1.2rem;
        height: 1.2rem;
        background: #AAA;
        border-radius: 50%;
        display: block;
      }

      .notification-title {
        flex : 1 1;
        margin-left: 5px;
        font-size: small;
      }
    }

    .title-line {
      font-size: medium;
      font-weight: bold;
      text-align: left;
    }

    .message-line {
      font-size: medium;
      font-weight: normal;
      text-align: left;
    }

  }

}