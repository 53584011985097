.notification-detail-screen {

  .statistics-title {
    font-size: large;
    font-weight: bold;
  }

  .statistics-value {
    font-size: xx-large;
    font-weight: bold;
  }

}