@import "../../colours";

.filter-text {

  .input-group-text {
    background: $background-primary;
  }

  .addon-icon {
    width: 1.2rem;
    height: 1.2rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
  }

  .clickable {
    cursor: pointer;

    &:hover {
      background-color: $grey-xlight;
    }
  }

}