@import "./colours";
@import "./dimensions";
@import "./tokyo";

body {
  font-family: 'Open Sans', sans-serif !important;
}

label {
  font-weight: bold;
}

.body-locked {
  overflow: hidden !important;
}

.app-screen {
  padding-bottom: 40px;
}

.header-wrapper {
  margin-bottom: 20px;
}

.badge.clickable, .form-control.clickable {
  cursor: pointer;
}

h3 {
  font-size: 20pt !important;
  font-weight: 800 !important;
  margin-bottom: 5px !important;
}

h4 {
  font-size: 1.5rem !important;
  font-weight: 500 !important;
}

.container {
  width: 100% !important;
}

.card {
  box-shadow: none !important;

  .card-header {
    background-color: #EBEBEB !important;
    box-shadow: none !important;
  }
}

table {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  thead {
    th {
      &:first-child {
        border-top-left-radius: 5px;
      }

      &:last-child {
        border-top-right-radius: 5px;
      }
    }
  }

  tbody {
    tr {
      &:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        td {
          &:first-child {
            border-bottom-left-radius: 5px;
          }
        }

        td {
          &:last-child {
            border-bottom-right-radius: 5px;
          }
        }
      }
    }
  }
}

.image-thumbnail {
  border: 1px solid $grey-light;
  border-radius: 5px;
  background-color: $grey-xlight;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.file-hide {
  width: 1px;
  height: 1px;
  overflow:hidden;
  position: absolute;
  left: -9999px;
  top: -9999px;
}

.empty-message {
  text-align: center !important;
  font-style: italic;
  font-size: large;
}

div.empty-message, span.empty-message {
  display: block;
}

.list-group-item {
  &.clickable:hover {
    background-color: $grey-xlight;
    cursor: pointer;
  }
}

.animate-screen-content {
  animation-name: app-screen-appear;
  animation-duration: 200ms;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;

  @keyframes app-screen-appear {
    0% { opacity : 0; transform: translateY(20%);}
    100% { opacity : 1; transform: translateY(0); }
  }
}

.progress {
  .progress-bar {
    height: 100%;
  }
}

/* HEADER NAV CONTENT */

.header-nav-content {
  //display: flex;
  //align-items: center;
  //padding-left: 40px;

  .header-project-selector {
    display: inline-block;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px 10px;

    .label {
      font-size: small;
      font-weight: 800;
    }

    .project-name {
      font-size: medium;
    }
  }

}

/* CARD */

.card {
  &.clickable {
    cursor: pointer;
  }

  .card-header {
    h1, h2, h3, h4 {
      margin: 0;
    }
  }
}

/* OFFCANVAS **/

.offcanvas {
  visibility: visible !important;
  animation-duration: 120ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  transform: translateX(0);
  z-index: 991 !important;

  &.offcanvas-start {
    animation-name: offcanvas-appear-start !important;
  }

  &.offcanvas-end {
    animation-name: offcanvas-appear-end !important;
  }

  &.offcanvas-bottom {
    animation-name: offcanvas-appear-bottom !important;
  }

  &.offcanvas-top {
    animation-name: offcanvas-appear-top !important;
  }

  .offcanvas-footer {
    padding: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }
}

.offcanvas-scrim {
  background: rgba(0, 0, 0, 0.6);
  animation-name: offcanvas-scrim-appear;
  animation-duration: 120ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 990;
}

.dismissing {
  .offcanvas {
    animation-direction: reverse !important;
    animation-play-state: running !important;
  }

  .offcanvas-scrim {
    animation-direction: reverse !important;
    animation-play-state: running !important;
  }
}

@keyframes offcanvas-scrim-appear {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

@keyframes offcanvas-appear-start {
  0% {
    transform: translateX(-100%)
  }
  100% {
    transform: translateX(0)
  }
}

@keyframes offcanvas-appear-end {
  0% {
    transform: translateX(100%)
  }
  100% {
    transform: translateX(0)
  }
}

@keyframes offcanvas-appear-bottom {
  0% {
    transform: translateY(100%)
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes offcanvas-appear-top {
  0% {
    transform: translateY(-100%)
  }
  100% {
    transform: translateY(0);
  }
}

/* SCREEN TITLE & ACTIONS */

.screen-actions {
  text-align: right;
  align-items: center;

  & > * {
    animation-name: screen-action-appear;
    animation-duration: 120ms;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
  }

  @keyframes screen-action-appear {
    0% { opacity : 0; transform: translateX(40%) }
    100% { opacity : 1; transform: translateX(0) }
  }
}